<template>
    <div class="container">
        <v-breadcrumbs :items="snackbar.breadcrumbsItem" large></v-breadcrumbs>
        <div slot="table-actions">
            <v-btn color="primary" @click.stop="addModal">Passenger Form</v-btn>
        </div>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <vue-good-table :columns="columns"
                        :rows="rows"
                        mode="remote"
                        :totalRows="customerData.totalRecords"
                        :pagination-options="{ enabled: true,mode: 'records',perPageDropdown: [50, 100,200] }"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-per-page-change="onPerPageChange"
                        styleClass="vgt-table condensed">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'flightDateTime'">
                    <span>{{props.row.flightDateTime | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                </span>
                <span v-else-if="props.column.field == 'actions'">
                    <v-icon small class="mr-2" @click.stop="editItem(props.row, 'CustomerForm')">edit</v-icon>
                    <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
                </span>
            </template>
            <template slot="loadingContent">
                <v-skeleton-loader class="mx-auto"
                                   max-width="300"
                                   type="table"></v-skeleton-loader>
            </template>
        </vue-good-table>
        <v-dialog
                v-model="snackbar.formModal"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                persistent
        >
            <v-card>
                <!-- <component :is="snackbar.dynamicComponent.current"
                            @dialogueClose="dialogueClose"
                            @formResponse="formResponse"
                            v-bind="customerData.updateItem"
                 ></component>-->
                <CustomerForm @dialogueClose="dialogueClose"
                              @formResponse="formResponse"
                              v-if="snackbar.createFormComponent"
                ></CustomerForm>
                <CustomerEditForm @dialogueClose="dialogueClose"
                                  @formResponse="formResponse"
                                  v-if="snackbar.editFormComponent"
                                  :editItemIndex="customerData.editItemIndex"
                ></CustomerEditForm>
            </v-card>
        </v-dialog>
        <v-snackbar
                v-model="snackbar.snackbar"
                color="success"
                :timeout="snackbar.timeout"
                top
        >
            {{ callbackResponse.message }}
            <v-btn
                    dark
                    text
                    @click="snackbar.snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import axios from "axios";
    import CustomerForm from "./CustomerForm";
    import CustomerEditForm from "./CustomerEditForm";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "HelicopterCustomerList",
        components: {
            'CustomerForm': CustomerForm,
            'CustomerEditForm': CustomerEditForm,
            deleteListData
        },
        methods: {
            fullName(rowObj) {
                return rowObj.firstName + '   ' + rowObj.lastName
            },
            formResponse(data) {
                if (data) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Country: this.serverParams.columnFilters.country,
                    FirstName: this.serverParams.columnFilters.firstName,
                    CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
                    PassportNumber: this.serverParams.columnFilters.passportNumber,
                    LicenseNumber: this.serverParams.columnFilters.licenseNumber,
                    FromDate: null,
                    ToDate: null
                };
                axios.post('HeliCopter/GetPassengerInformationListAsync', param).then(response => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                    this.snackbar.loading = false;
                })
                    .catch(err => {
                        console.log(err)
                    });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "HeliCopter/DeletePassengerInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.loadItems()
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {
                        label: "S No.",
                        field: "countIndex"
                    },
                    {
                        label: 'Full Name',
                        field: this.fullName
                    },

                    {
                        label: 'Nationality',
                        field: 'nationality',
                    },
                    {label: "Actions", field: "actions"}
                ],
                rows: [],
                dialogDelete: false,
                deleteFormData: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "personInformationID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    breadcrumbsItem: [
                        {
                            text: 'Dashboard',
                            disabled: false,
                            to: 'HelicopterDashboard',
                            exact: true

                        },
                        {
                            text: 'Helicopter',
                            disabled: true,
                        },
                    ],
                    dynamicComponent: {
                        current: 'CustomerForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
