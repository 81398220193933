<template>
    <v-card>
        <v-toolbar class="darken-1" color="blue" dark fixed>
            <v-toolbar-title>
                Add Passenger
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn @click="dialogueClose" dark text>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>

                    <v-card class="my-12">
                        <v-toolbar class="darken-1" color="blue" dark fixed>
                            <v-toolbar-title>
                            Find Previous ID
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="6"
                                >
                                    <v-autocomplete
                                            :items="citizenshipData"
                                            :loading="isLoading"
                                            :search-input.sync="searchCitizen"
                                            @input="getUserData"
                                            color="white"
                                            hide-selected
                                            clearable
                                            item-text="id"
                                            item-value="value"
                                            label="Enter your ID"
                                            placeholder="Citizenship Number"
                                            prepend-icon="mdi-database-search"
                                            return-object
                                            v-model="dropdownData.citizenShipNumber"
                                    ></v-autocomplete>

                                </v-col>

                                <v-col
                                        cols="12"
                                        sm="6"
                                >
                                    <v-autocomplete
                                            :items="passportData"
                                            :loading="isLoading"
                                            :search-input.sync="searchPassport"
                                            @input="getUserData"
                                            color="white"
                                            hide-selected
                                            clearable
                                            item-text="id"
                                            item-value="value"
                                            label="Enter your ID"
                                            placeholder="Passport Number"
                                            prepend-icon="mdi-database-search"
                                            return-object
                                            v-model="dropdownData.passportNumber"
                                    ></v-autocomplete>

                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-row>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="First Name "
                                    required
                                    v-model="customerData.firstName"
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Last Name"
                                    required
                                    v-model="customerData.lastName"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-radio-group label="Gender" row v-model="customerData.gender">
                                <v-radio label="Male" value="male"></v-radio>
                                <v-radio label="Female" value="female"></v-radio>
                                <v-radio label="Other" value="other"></v-radio>
                            </v-radio-group>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.dateMenu"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            clearable
                                            label="Date of Birth"
                                            prepend-icon="event"
                                            readonly
                                            v-model="customerData.dateOfBirth"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Nationality"
                                    required
                                    v-model="customerData.nationality"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-autocomplete
                                    :items="countries"
                                    @change="getState"
                                    clearable
                                    item-text="value"
                                    item-value="id"
                                    label="Country"
                                    single-line
                                    v-model="customerData.country"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-autocomplete
                                    :items="state"
                                    clearable
                                    item-text="value"
                                    item-value="id"
                                    label="State"
                                    single-line
                                    v-model="customerData.state"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               md="4" sm="6">
                            <v-select :items="airList" item-text="value"
                                      item-value="id"
                                      label="Flight Number"
                                      v-model="customerData.flightNumber"></v-select>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Flight PNR Number"
                                    required
                                    v-model="customerData.flightPNRNo"
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Ticket Number"
                                    required
                                    v-model="customerData.ticketNumber"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               md="4" sm="6">
                            <v-autocomplete :items="FlightClasses"
                                            item-text="value"
                                            item-value="value"
                                            label="Flight Class"
                                            v-model="customerData.flightClass"
                            ></v-autocomplete>
                        </v-col>
                        <!--<v-col cols="12"
                               md="4" sm="6">
                            <v-autocomplete :items="PassengerTypes" label="Passenger Type"
                                            v-model="customerData.PassengerType"></v-autocomplete>
                        </v-col>-->
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-autocomplete
                                    :items="Services"
                                    chips
                                    clearable
                                    deletable-chips
                                    item-text="value"
                                    item-value="id"
                                    label="Services"
                                    multiple
                                    v-model="customerData.passengerServiceID"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-autocomplete
                                    :items="AirPackages"
                                    chips
                                    clearable
                                    deletable-chips
                                    item-text="value"
                                    item-value="id"
                                    label="Air Package"
                                    multiple
                                    v-model="customerData.packageID"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-autocomplete :items="Destinations"
                                            item-text="value"
                                            item-value="value"
                                            label="Destinations"
                                            v-model="customerData.destination"></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.dateMenu2"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            clearable
                                            label="Flight Date"
                                            prepend-icon="event"
                                            readonly
                                            v-model="customerData.flightDateTime"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.FlightDateTime">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Citizenship Number"
                                    required
                                    v-model="customerData.citizenShipNumber"
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Passport Number"
                                    required
                                    v-model="customerData.passportNumber"
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="License Number "
                                    required
                                    v-model="customerData.licenseNumber"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Other Id "
                                    required
                                    v-model="customerData.otherID"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Document"
                                    required
                                    v-model="customerData.document"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-switch
                                    class="col-sm-6 col-md-4"
                                    clearable
                                    label="Is Verified Document?"
                                    v-model="customerData.isVerifiedDocument"
                            ></v-switch>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-text-field
                                    label="Issued By"
                                    required
                                    v-model="customerData.issuedBy"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                                sm="6"
                        >
                            <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.dateMenu3"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            clearable
                                            label="Issued Date"
                                            prepend-icon="event"
                                            readonly
                                            v-model="customerData.issuedDate"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogueClose" color="red darken-1" dark>Cancel</v-btn>
            <v-btn @click="formSubmit" color="blue darken-1" dark>Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "HelicopterCustomerForm",
        data() {
            return {
                model: null,
                items:[],
                customerData: {},
                customerSubmitData: {},
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                AirPackages: [],
                PassengerTypes: ['Adult', 'Child', 'Old'],
                FlightClasses: [],
                Issuer: ['E-Sewa'],

                citizenshipData:[],
                passportData:[],
                dropdownData:{
                    citizenShipNumber:"",
                    passportNumber:""
                },
                isLoading: false,
                searchCitizen: null,
                searchPassport: null,
            }
        },
        watch: {
            "dropdownData.citizenShipNumber": {
                handler: function (val) {
                    if (val) {
                       axios.get("Shared/GetPersonInformationByCitizenshipAsync",{
                           params:{
                               CitizenShipNumber: val.value
                           }
                       }).then(response=>{
                           this.customerData = response.data
                           if(response.data.country){
                               this.getState(response.data.country)
                           }
                       }).catch(err=>{
                           console.log(err)
                       });
                    }
                },
                deep: true
            },
            "dropdownData.passportNumber": {
                handler: function (val) {
                    if (val) {
                       axios.get("Shared/GetPersonInformationByPassportAsync",{
                           params:{
                               passportNumber: val.value
                           }
                       }).then(response=>{
                           this.customerData = response.data
                           if(response.data.country){
                               this.getState(response.data.country)
                           }
                       }).catch(err=>{
                           console.log(err)
                       });
                    }
                },
                deep: true
            },
            searchCitizen(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items

                        axios.get("Track/GetCitizenIDListAsync", {
                            params: {
                                CitizenID: val
                            }
                        })
                            .then(res => {
                                this.citizenshipData = res.data
                            })
                            .catch(err => {
                                console.log(err)
                            })
                            .finally(() => (this.isLoading = false))
                    }
            },
            searchPassport(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items
                  axios.get("Track/GetPassportIDListAsync", {
                            params: {
                                PassportNumber: val
                            }
                        })
                            .then(res => {
                                this.passportData = res.data
                            })
                            .catch(err => {
                                console.log(err)
                            })
                            .finally(() => (this.isLoading = false))
                }
            },
        },
        computed: {},
        methods: {

            getUserData(val) {
                if (val) {
                    this.userInfo = []
                    axios.get('Track/TrackTouristByID', {
                        params: {
                            ID: val.value,
                            Type: this.trackType
                        }
                    }).then(response => {
                        this.userInfo = response.data
                    })
                }
            },

            dialogueClose() {
                this.customerData = {}
                this.customerFormData = []
                this.$emit('dialogueClose')
            },
            formSubmit() {
                this.customerSubmitData = this.customerData
                if (this.customerData.passengerServiceID) {
                    this.customerSubmitData.passengerServiceID = this.customerData.passengerServiceID.join()
                }
                if (this.customerData.packageID) {
                    this.customerSubmitData.packageID = this.customerData.packageID.join()
                }
                this.customerFormData.push(this.customerSubmitData)
                axios.post('HeliCopter/InsertIntoPassengerInformationAsync', this.customerFormData)
                    .then(() => {
                        this.message.status = true
                        this.message.text = 'Passenger is Successfully Added'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
                    .catch(() => {
                        this.message.status = false
                        this.message.text = 'Error Adding Service, Please Contact Admin'
                        this.$emit('formResponse', this.message)
                    })
            },
            getState(val) {
                axios.get('Shared/StateListByCountryCode?CountryCode=' + val).then(response=>{
                     this.state = response.data
                }).catch(err=>{
                    console.log(err)
                });
            },
            async formData() {
                axios.get("Shared/CountryList")
                    .then(response => {
                        this.countries = response.data;
                    }).catch(err => {
                    console.log(err)
                });
                axios.get("HeliCopter/DDLHeliCopterDetailListAsync")
                    .then(response => {
                        this.airList = response.data
                    }).catch(err => {
                    console.log(err)
                });
                axios.get("Aviation/DDLAirDestinationByIDAsync")
                    .then(response => {
                        this.Destinations = response.data
                    }).catch(err => {
                    console.log(err)
                })

                axios.get("Aviation/DDLPassengerServiceListAsync").then(response => {
                    this.Services = response.data
                }).catch(err => {
                    console.log(err)
                })

                axios.get("StaticValues/DDLCommonStaticValuesListAsync/101")
                    .then(response => {
                        this.FlightClasses = response.data
                    }).catch(err => {
                    console.log(err)
                })
                axios.get('HeliCopter/DDLAirPackageListAsync').then(response => {
                    this.AirPackages = response.data
                }).catch(err => {
                    console.log(err)
                });

            },


        },
        mounted() {
            this.formData();
        },
    }
</script>

<style scoped>

</style>